import React from 'react';
import { Link } from 'gatsby';
import Image from './Image';

import KespaEmail from './KespaEmail';
import KespaPhone from './KespaPhone';
import KespaSkype from './KespaSkype';

const Footer = () => (
  <div className="footer-wrapper">
    <div className="container">
      <footer>
        <div className="row">
          <div className="col-md-2">
            <Image src="logo.png" alt="КЭСПА" height="39" width="97" />
          </div>
          <div className="col-md-10 contacts">
            <KespaPhone />
            <KespaEmail />
            <KespaSkype />
            <Link className="contact-link" to="/offer">
              Оферта
            </Link>
          </div>
        </div>
      </footer>
    </div>
  </div>
);

export default Footer;
