import * as React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import axios from 'axios';

import { FormSubmitButton } from './FormSubmitButton';
import FormFailMessage from './FormFailMessage';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const UNHANDLED_ERROR = 'UNHANDLED_ERROR';

const customPhoneInput = (props, ref) => {
  return <input {...props} ref={ref} className="form-control" />;
};

const customPhoneInputForwardRef = React.forwardRef(customPhoneInput);

const customCountrySelectComponent = () => null;

const GeneralErrorMessage = ({ message }) => (
  <div className="form-group has-error">
    <p className="help-block" dangerouslySetInnerHTML={{ __html: message }} />
  </div>
);

const FormInnerPhoneField = ({ setFieldValue, ...props }) => {
  const onChangePhoneInput = React.useCallback(
    value => setFieldValue('phone', value),
    [setFieldValue]
  );

  return (
    <PhoneInput
      {...props}
      inputComponent={customPhoneInputForwardRef}
      countrySelectComponent={customCountrySelectComponent}
      defaultCountry="RU"
      onChange={onChangePhoneInput}
    />
  );
};

const FormInnerField = ({ textarea, setFieldValue, value, name, ...props }) => {
  const Comp = textarea ? 'textarea' : 'input';
  const inputRef = React.useRef(null);

  const valueRef = React.useRef(value);

  React.useEffect(() => {
    valueRef.current = value;
  });

  React.useEffect(() => {
    setTimeout(() => {
      if (inputRef.current && inputRef.current.value !== valueRef.current) {
        setFieldValue(name, inputRef.current.value);
      }
    }, 200);
  }, [name, setFieldValue]);

  return <Comp ref={inputRef} name={name} {...props} />;
};

const FormField = ({ name, type, label, textarea }) => {
  return (
    <React.Fragment>
      <Field
        name={name}
        render={({
          field: { ...fieldProps },
          form: { setFieldValue },
          ...props
        }) => {
          return (
            <div className="form-group">
              {name === 'phone' ? (
                <FormInnerPhoneField
                  {...fieldProps}
                  {...props}
                  setFieldValue={setFieldValue}
                  placeholder={label}
                />
              ) : (
                <FormInnerField
                  className="form-control"
                  textarea={textarea}
                  name={name}
                  type={type}
                  placeholder={label}
                  setFieldValue={setFieldValue}
                  {...fieldProps}
                  {...props}
                />
              )}
            </div>
          );
        }}
      />
      <ErrorMessage
        name={name}
        render={error => (
          <div className="has-error">
            <p className="help-block">{error}</p>
          </div>
        )}
      />
    </React.Fragment>
  );
};

export const defaultSubmitResponseHandler = response => {
  const unhandledError = { _error: UNHANDLED_ERROR };

  if (!response || response.status >= 500) {
    return unhandledError;
  }

  const { errors } = response.data;

  if (Array.isArray(errors) && errors.length) {
    return response.data.errors.reduce(
      (obj, next) => {
        if (next.name === 'ValidationError' && next.key) {
          obj[next.key] = next.message;
        } else {
          obj._error.push(next.message);
        }
        return obj;
      },
      { _error: [] }
    );
  }

  return unhandledError;
};

const Form = ({
  buttonText = 'Отправить',
  fields,
  validate,
  submitUrl,
  submitResponseHandler,
  initialValues = {},
  successMessage: SuccessMessage,
  form,
  onSubmit,
  onSubmitSuccess = () => {},
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validate={values => validate(values) || {}}
      render={({
        handleSubmit,
        errors,
        status,
        isSubmitting,
        handleReset,
        values,
      }) => {
        if (errors._error === UNHANDLED_ERROR) {
          return <FormFailMessage />;
        }

        if (status === 'success' && SuccessMessage) {
          return <SuccessMessage reset={handleReset} values={values} />;
        }

        return (
          <form onSubmit={handleSubmit} id={form}>
            {fields.map(
              ({ name, type, label, textarea }) =>
                type !== 'hidden' && (
                  <FormField
                    key={name}
                    type={type}
                    name={name}
                    label={label}
                    textarea={textarea}
                  />
                )
            )}
            {errors._error &&
              errors._error.map((errorMessage, i) => (
                <GeneralErrorMessage key={i} message={errorMessage} />
              ))}
            <div className="form-group">
              <FormSubmitButton submitting={isSubmitting}>
                {buttonText}
              </FormSubmitButton>
            </div>
          </form>
        );
      }}
      onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
        const submitPromise = onSubmit
          ? onSubmit(values)
          : axios.post(`/api${submitUrl}`, values);

        return submitPromise
          .then(response => {
            setStatus('success');
            setSubmitting(false);
            onSubmitSuccess(response);
          })
          .catch(({ response }) => {
            const handleError =
              submitResponseHandler || defaultSubmitResponseHandler;

            setErrors(handleError(response));
            setSubmitting(false);
          });
      }}
    />
  );
};

export default Form;
