import React from 'react';
import { getImgUrl } from '../helpers';

const Logo = () => (
  <img
    src={getImgUrl('kespa_logo_true_blue.png')}
    alt="КЭСПА"
    height="39"
    width="97"
    className="logo"
  />
);

export default Logo;
