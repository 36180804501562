import React from 'react';

const KespaEmail = ({ href, ...props }) => (
  <a className="contact-link" href="mailto:mail@kespa.ru" {...props}>
    <i className="fa fa-envelope" />
    mail@kespa.ru
  </a>
);

export default KespaEmail;
