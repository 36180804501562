import React from 'react';

const KespaSkype = props => (
  <a className="contact-link" href="https://t.me/KespaSchoolBot" {...props}>
    <i className="fa fa-telegram" />
    KespaSchoolBot
  </a>
);

export default KespaSkype;
