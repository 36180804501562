import React from 'react';

const KespaPhone = props => (
  <a {...props} className="contact-link" href="tel:+74996476044">
    <i className="fa fa-phone" />
    +7 (499) 647-60-44
  </a>
);

export default KespaPhone;
