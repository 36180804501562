import React from 'react';
import PropTypes from 'prop-types';

export const FormSubmitButton = ({ submitting, children }) => (
  <button
    type="submit"
    className="btn btn-block"
    style={{ whiteSpace: 'normal' }}
  >
    {submitting ? (
      <i className="fa fa-circle-o-notch fa-spin fa-2x fa-fw" />
    ) : (
      children
    )}
  </button>
);

FormSubmitButton.propTypes = {
  submitting: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
