import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Logo from './Logo';
import KespaPhone from './KespaPhone';
import LoginButton from './LoginButton';
// import { toggleHeader } from '../actionCreators';
// import { getIsHeaderExpanded } from "../reducer"

const defaultNavLinks = [
  {
    to: '/#method-section',
    text: 'Методика',
  },
  {
    to: '/publications',
    text: 'Книги',
  },
  {
    to: '/#prices',
    text: 'Цены',
  },
  {
    to: '/contacts',
    text: 'Контакты',
  },
];

const NavList = ({ navLinks, isExpanded, toggle, ulClassName }) => {
  return (
    <ul className={ulClassName}>
      {navLinks.map(({ to, text }, i) => (
        <li key={i}>
          <Link
            to={to}
            activeClassName="active"
            onClick={() => isExpanded && toggle()}
          >
            {text}
          </Link>
        </li>
      ))}
    </ul>
  );
};

const Collapser = ({ toggle }) => (
  <div className="collapser">
    <button type="button" onClick={toggle}>
      <span className="icon-bar" />
      <span className="icon-bar" />
      <span className="icon-bar" />
    </button>
  </div>
);

const Header = ({ navLinks = defaultNavLinks }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <header>
      <div className="container hidden-xs hidden-sm">
        <nav>
          <Link to={'/'}>
            <Logo />
          </Link>

          <NavList
            navLinks={navLinks}
            isExpanded
            toggle={toggle}
            ulClassName={'nav-main'}
          />

          <div className="topbar_phone">
            <KespaPhone />
          </div>
          <LoginButton />
        </nav>
      </div>

      <div className="container visible-xs visible-sm">
        <div className="row nav-top-mobile">
          <div className="nav-top-mobile-logo-with-burger">
            <Collapser toggle={toggle} />

            <Link to={'/'}>
              <Logo />
            </Link>
          </div>

          <LoginButton />
        </div>

        <div className="row">
          <nav className={isExpanded ? 'expanded' : 'collapsed'}>
            <NavList
              navLinks={navLinks}
              isExpanded={isExpanded}
              toggle={toggle}
              ulClassName={'nav-main'}
            />
          </nav>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  navLinks: PropTypes.array,
};

// const mapStateToProps = state => ({
//   isExpanded: getIsHeaderExpanded(state),
// })

// const mapDispatchToProps = {
//   toggle: () => toggleHeader(),
// };

export default Header;
