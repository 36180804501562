import React from 'react';
import Section from './Section';

const wrapInSection = ComposedComponent => props => (
  <Section>
    <ComposedComponent {...props} />
  </Section>
);

export default wrapInSection;
