import React from 'react';
import PropTypes from 'prop-types';

const LoginButton = ({
  url = process.env.GATSBY_CLASSROOM_URL,
  title = 'Войти',
}) => (
  <a href={url} className="btn login-button" title={title}>
    {title}
  </a>
);

LoginButton.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
};

export default LoginButton;
