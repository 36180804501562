import React from 'react';
import PropTypes from 'prop-types';
import { getImgUrl } from '../helpers';

const generateSrcset = (...srcArray) =>
  srcArray.map((src, i) => `${src} ${i + 1}x`).join(', ');

const Image = ({ src, noSrcSet, className, ...props }) => {
  const imgUrl = getImgUrl(src);
  const imgUrl2x = getImgUrl(`x2/${src}`);

  return (
    <img
      src={imgUrl}
      srcSet={noSrcSet ? undefined : generateSrcset(imgUrl, imgUrl2x)}
      className={`img-responsive ${className}`}
      {...props}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  noSrcSet: PropTypes.bool,
};

export default Image;
