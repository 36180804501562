import React from 'react';
import Layout from './Layout';

const wrapInLayout = ComposedComponent => props => (
  <Layout>
    <ComposedComponent {...props} />
  </Layout>
);

export default wrapInLayout;
