import React from 'react';
import { Link } from 'gatsby';

const defaultErrorMessage = (
  <div>
    <p>
      УПС! Что-то пошло не так и все сломалось o.0 Попробуйте позднее,
      пожалуйста! А если всё равно не заработает –{' '}
      <Link to="/contacts">напишите&nbsp;нам</Link>.
    </p>
  </div>
);

const FormFailMessage = ({ message = defaultErrorMessage }) => (
  <div className="fail-message">
    <div className="fa-wrapper">
      <i className="fa fa-exclamation-triangle" />
    </div>
    {message}
  </div>
);

export default FormFailMessage;
