import React from 'react';
import Helmet from 'react-helmet';

import Header from './Header';
import Footer from './Footer';
import { getImgUrl } from '../helpers';

import '../styles/main.scss';

const helmetProps = {
  link: [
    { rel: 'shortcut icon', href: getImgUrl('favicon.ico?v=2') },
    {
      rel: 'stylesheet',
      href:
        'https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i&amp;subset=cyrillic',
    },
    {
      rel: 'stylesheet',
      href:
        'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css',
    },
  ],
};

const Layout = ({ children }) => (
  <div>
    <Helmet {...helmetProps}>
      <meta
        property="og:image"
        content="http://classroom.kespa.ru/cdn/images/1x/aux/kespa_preview_v4_tiny.png"
      />
      <meta
        property="og:description"
        content="КЭСПА – онлайн-школа английского с правильной методикой."
      />
      {+process.env.GATSBY_TEST && <meta name="robots" content="noindex" />}
    </Helmet>
    <Header />
    {children}
    <Footer />
  </div>
);

export default Layout;
